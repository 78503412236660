<template>
    <div
        class="fixed inset-0 w-full z-9999 duration-300 flex justify-center items-center"
        :class="isOpenSearch ? 'opacity-100' : 'opacity-0 pointer-events-none'"
    >
        <div class="absolute w-full h-full bg-overlay-03 z-1" @click="$emit('hideSearch', false)"></div>
        <div class="w-800px bg-white z-2 rounded-12px pr-0.5 pb-1.5">
            <div class="p-3 w-full">
                <form class="flex items-center justify-between w-full relative" @submit.prevent="handleSearch">
                    <input type="text" class="w-full" v-model="textSearch" placeholder="Tìm kiếm sản phẩm..." />
                    <global-loading-search v-if="showLoading" />
                    <span v-else class="i-ic:baseline-search w-6 h-6 text-black cursor-pointer"></span>
                </form>
            </div>
            <div
                class="p-6 border-t border-solid border-[rgba(204, 204, 204, 0.80)] h-[584px] overflow-y-scroll custom-scrollbar"
            >
                <div class="flex gap-6 flex-col">
                    <nuxt-link
                        :to="item?.url"
                        v-for="item in dataSearch"
                        class="flex gap-6 group"
                        @click="$emit('hideSearch', false)"
                    >
                        <div class="w-[128px] h-[128px] overflow-hidden b-0.5 b-gray-150">
                            <NuxtImg
                                :src="getThumbnail(item?.images[0], 200, true)"
                                width="200"
                                sizes="sm:100vw md:50vw lg:200px "
                                loading="lazy"
                                :alt="item?.title"
                                :placeholder="[50, 25, 75, 5]"
                                class="w-full h-full object-cover duration-250 transform group-hover:scale-115"
                            />
                        </div>
                        <div class="flex flex-col gap-3">
                            <div class="text-blacks-100 text-xl font-medium hover:underline">{{ item?.title }}</div>
                            <div class="text-blacks-70 text-[14px]">{{ item?.sku }}</div>
                            <div v-if="item?.price" class="flex gap-2">
                                <div class="text-blacks-100 text-xl font-medium">{{ item?.price }}</div>
                                <div class="text-blacks-100 text-[14px] font-medium uppercase">vnđ</div>
                            </div>
                        </div>
                    </nuxt-link>
                </div>
                <div v-if="dataSearch?.length === 0" class="text-center text-lg text-blacks-100 font-medium">
                    Không tìm thấy sản phẩm phù hợp
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
const props = defineProps({
    isOpenSearch: Boolean
})
const showLoading = ref(false)
const emits = defineEmits(['hideSearch'])
const textSearch = ref('')
const dataSearch = ref()
const { searchProducts } = useProducts()
const res: any = await searchProducts({}, { text: textSearch.value }, { limit: 9, page: 1 })
dataSearch.value = res?.products
watchDebounced(
    () => textSearch.value,
    async () => {
        showLoading.value = true
        const res = await searchProducts({}, { text: textSearch.value }, { limit: 9, page: 1 })
        dataSearch.value = res?.products
        showLoading.value = false
    },
    { debounce: 500 }
)
const router = useRouter()
const handleSearch = () => {
    emits('hideSearch', false)
    router.push({ name: 'search', query: { text: textSearch.value } })
}
watch(
    () => props.isOpenSearch,
    async () => {
        if (props.isOpenSearch == true) {
            document.querySelector('body').classList.add('overflow-hidden')
        } else {
            document.querySelector('body').classList.remove('overflow-hidden')
        }
    }
)
</script>
